<div
  class="file-upload-container"
  fileDragAndDrop
  (filesChangeEmiter)="dropHandler($event, 'dragAndDrop')"
>
  <ng-container *ngIf="imageUrls?.length < 1; else checkFilesLoading">
    <div class="no-files">{{ "storyForm.dropImagesHere" | translate }}</div>
  </ng-container>
  <ng-template #checkFilesLoading>
    <ng-container *ngIf="componentLoading; else showFiles">
      <div class="spinner-container">
        <p-progressSpinner></p-progressSpinner>
      </div>
    </ng-container>
  </ng-template>
  <ng-template #showFiles>
    <div class="carousel-container">
      <p-carousel
        [value]="imageUrls"
        [numVisible]="7"
        [numScroll]="1"
        [showIndicators]="false"
        [responsiveOptions]="responsiveOptions"
        orientation="horizontal"
      >
        <ng-template let-file pTemplate="item">
          <ng-container
            *ngIf="
              file?.mime === 'video/mp4' || file?.mime === 'video/quicktime';
              else showImage
            "
          >
            <div class="story-video">
              <div class="remove-story-button clickable">
                <i
                  class="pi pi-times"
                  style="color: red"
                  (click)="removeImage(file.order)"
                ></i>
              </div>
              <!--     <div class="story-order-arrows">
                    <mat-icon *ngIf="!first" class="left-arrow" (click)="updateFileOrder(image.order, -1)">chevron_left</mat-icon>
                    <mat-icon *ngIf="!last" class="right-arrow" (click)="updateFileOrder(image.order, 1)">chevron_right</mat-icon>
                  </div> -->
            </div>
          </ng-container>
          <ng-template #showImage>
            <div class="story-image-container">
              <img [src]="file?.url" class="story-image" />
              <div class="remove-story-button clickable">
                <i
                  class="pi pi-times"
                  style="color: #ffffff"
                  (click)="removeImage(file.order)"
                ></i>
              </div>
              <!-- <div class="story-order-arrows">
                    <mat-icon *ngIf="!first" class="left-arrow" (click)="updateFileOrder(image.order, -1)">chevron_left</mat-icon>
                    <mat-icon *ngIf="!last" class="right-arrow" (click)="updateFileOrder(image.order, 1)">chevron_right</mat-icon>
                  </div> -->
            </div>
          </ng-template>
        </ng-template>
      </p-carousel>
    </div>
  </ng-template>
</div>
