import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthVisibleForm } from 'libs/enums/auth';
import { AuthState } from 'libs/states/auth';
import * as authActions from 'libs/states/auth/+state/auth.actions';
import * as authSelectors from 'libs/states/auth/+state/auth.selectors';
import { LoginData, SetPasswordData } from 'libs/types/auth';

@Component({
  selector: 'app-auth-container',
  templateUrl: './auth-container.component.html',
  styleUrls: ['./auth-container.component.scss'],
})
export class AuthContainerComponent implements OnInit {
  visibleFormEnum = AuthVisibleForm;

  authStep$ = this.authState.select(authSelectors.getAuthStep);

  activeAccountStep$ = this.authState.select(
    authSelectors.getActivateAccountStep
  );

  forgotPasswordStep$ = this.authState.select(
    authSelectors.getForgotPasswordStep
  );

  constructor(private authState: Store<AuthState>, private router: Router) {}

  ngOnInit(): void {
    this.router.navigate(['login']);
  }

  login(loginData: LoginData) {
    this.authState.dispatch(authActions.login({ loginData }));
  }

  updateActivateAccountStep(step: number) {
    this.authState.dispatch(authActions.updateActivateAccountStep({ step }));
  }

  requestActivationCode(email: string) {
    this.authState.dispatch(authActions.requestActivationCode({ email }));
  }

  requestResetPasswordActivationCode(email: string) {
    this.authState.dispatch(authActions.requestResetPasswordCode({ email }));
  }

  navigateToForm(step: number) {
    this.authState.dispatch(authActions.updateAuthStep({ step }));
  }

  setPassword(data: SetPasswordData) {
    this.authState.dispatch(authActions.setPassword({ data }));
  }

  resetPassword(data: SetPasswordData) {
    this.authState.dispatch(authActions.resetPassword({ data }));
  }

  updateForgotPasswordStep(step: number) {
    this.authState.dispatch(authActions.updateForgotPasswordStep({ step }));
  }
}
