import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { INotification } from 'libs/types/notifications/interfaces/notification.interface';
import { Notification } from 'libs/types/notifications/notification.type';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { endpoints } from './notifications.endpoints';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  baseApiRoute = environment.baseApiRoute;

  constructor(private http: HttpClient) {}

  loadAllNotifications(read: boolean, limit: number, offset: number) {
    const url = `${this.baseApiRoute}${endpoints.loadAllNotifications}`;

    let params = new HttpParams();
    // params = params.append('filter[read]', read);

    params = params.append('page[offset]', offset);
    params = params.append('page[limit]', limit);

    return this.http.get<INotification>(url, { params }).pipe(
      map((response) => {
        return {
          data: response.data.map((x) => new Notification(x)),
          meta: response.meta,
        };
      })
    );
  }

  loadUnreadNotificationsCount() {
    const url = `${this.baseApiRoute}${endpoints.loadAllNotifications}`;

    let params = new HttpParams();
    params = params.append('filter[read]', false);
    params = params.append('page[offset]', 0);
    params = params.append('page[limit]', 0);

    return this.http
      .get<INotification>(url, { params })
      .pipe(map((response) => response.meta.page.total));
  }

  markAsRead() {
    const url = `${this.baseApiRoute}${endpoints.markAsRead}`;

    return this.http.post(url, {});
  }
}
