import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'libs/services/auth';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, filter, tap } from 'rxjs';

@Component({
  selector: 'app-registration-container',
  templateUrl: './registration-container.component.html',
  styleUrl: './registration-container.component.scss',
})
export class RegistrationContainerComponent {
  tokenFromUrl = null;

  showPassword = false;
  showConfirmPassword = false;

  registrationForm = this.fb.group({
    token: ['', [Validators.required]],
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(7)]],
    confirmPassword: ['', [Validators.required, Validators.minLength(7)]],
  });

  urlParams$ = this.activatedRoute.queryParams.pipe(
    tap((data: any) => {
      if (!data?.token) return;

      this.tokenFromUrl = data.token;
      this.populateToken(data.token);
    })
  );

  registrationSuccessful = new BehaviorSubject<boolean>(false);
  registrationSuccessful$ = this.registrationSuccessful.asObservable();

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private service: AuthService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService
  ) {}

  get passwordType() {
    return this.showPassword ? 'text' : 'password';
  }

  get confirmPasswordType() {
    return this.showConfirmPassword ? 'text' : 'password';
  }

  viewPassword() {
    this.showPassword = !this.showPassword;
  }

  viewConfirmPassword() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  populateToken(token: string) {
    const control = this.registrationForm.get('token');

    control.patchValue(token);
    control.disable();
    control.updateValueAndValidity();
  }

  submit() {
    const summary = this.translate.instant('general.error');

    if (this.registrationForm.invalid) {
      const detail = this.translate.instant('registration.fillRequired');

      this.messageService.add({
        severity: 'error',
        summary,
        detail,
      });
      return;
    }

    const formValue = this.registrationForm.value;

    if (formValue.password !== formValue.confirmPassword) {
      const detail = this.translate.instant(
        'registration.passwordsNotMatching'
      );

      this.messageService.add({
        severity: 'error',
        summary,
        detail,
      });
      return;
    }

    const data = {
      token: this.tokenFromUrl ?? formValue.token,
      first_name: formValue.firstName,
      last_name: formValue.lastName,
      email: formValue.email,
      password: formValue.password,
      password_repeat: formValue.confirmPassword,
    };

    this.service
      .registerNewAccount(data)
      .pipe(
        filter((response) => !!response),
        tap(() => {
          this.registrationSuccessful.next(true);
        })
      )
      .subscribe();
  }
}
