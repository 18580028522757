import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { TranslateService } from '@ngx-translate/core';
import { SurveyService } from 'libs/services/survey/survey.service';
import { Survey } from 'libs/types/survey';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { MessageService } from 'primeng/api';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import * as actions from './survey.actions';

@Injectable()
export class SurveyEffects {
  loadActiveSurveysCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadActiveSurveysCount),
      switchMap(() =>
        this.service.loadActiveSurveysCount().pipe(
          map((count: number) => {
            return actions.loadActiveSurveysCountSuccess({
              count,
            });
          }),
          catchError((error) => {
            return of(actions.loadActiveSurveysCountError({ error }));
          })
        )
      )
    )
  );

  loadActiveSurveys$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadActiveSurveys),
      switchMap(() =>
        this.service.loadActiveSurveys().pipe(
          map((surveys: Survey[]) => {
            return actions.loadActiveSurveysSuccess({
              surveys,
            });
          }),
          catchError((error) => {
            return of(actions.loadActiveSurveysError({ error }));
          })
        )
      )
    )
  );

  loadSurveyById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadSurvey),
      switchMap(({ id }) =>
        this.service.loadSurveyById(id).pipe(
          map((survey: Survey) => {
            return actions.loadSurveySuccess({
              survey,
            });
          }),
          catchError((error) => {
            return of(actions.loadSurveyError({ error }));
          })
        )
      )
    )
  );

  surveyAnsweredSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.surveyAnsweredSuccess),
      tap(() => {
        const summary = this.translate.instant('general.success');
        const detail = this.translate.instant('surveys.successMessage');

        this.messageService.add({
          severity: 'success',
          summary,
          detail,
        });

        this.analyticsService.event('survey-submitted');
      }),
      map(() => {
        return actions.loadActiveSurveys();
      })
    )
  );

  constructor(
    private actions$: Actions,
    private service: SurveyService,
    private messageService: MessageService,
    private translate: TranslateService,
    private analyticsService: GoogleAnalyticsService
  ) {}
}
