import { Action, createReducer, on } from '@ngrx/store';
import {
  ActivateAccountStepsEnum,
  AuthVisibleForm,
  ForgotPasswordStepsEnum,
} from 'libs/enums/auth';
import { LocalStorageClass } from 'libs/shared/functions/local-storage.class';
import { User } from 'libs/types/auth';
import * as actions from './auth.actions';

export const authFeatureKey = 'auth';

export interface AuthState {
  activateAccountStep: number;
  authStep: number;
  forgotPasswordStep: number;
  loading: boolean;
  currentUser?: User;
  error?: any;
}

export const initialState: AuthState = {
  loading: false,
  currentUser: LocalStorageClass.getCurrentUser(),
  activateAccountStep: ActivateAccountStepsEnum.Introduction,
  authStep: AuthVisibleForm.login,
  forgotPasswordStep: ForgotPasswordStepsEnum.RequestCode,
};

export const authReducer = createReducer(
  initialState,
  on(actions.login, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(actions.loginSetCurrentUser, actions.setCurrentUser, (state, action) => {
    return {
      ...state,
      loading: false,
      currentUser: action.user,
    };
  }),
  on(actions.updateActivateAccountStep, (state, action) => {
    return {
      ...state,
      activateAccountStep: action.step,
    };
  }),
  on(actions.requestActivationCodeSuccess, (state, action) => {
    return {
      ...state,
      activateAccountStep: ActivateAccountStepsEnum.CodeReceivedConfirmation,
    };
  }),
  on(actions.requestResetPasswordCodeSuccess, (state, action) => {
    return {
      ...state,
      forgotPasswordStep: ForgotPasswordStepsEnum.CodeReceivedConfirmation,
    };
  }),
  on(actions.updateAuthStep, (state, action) => {
    return {
      ...state,
      authStep: action.step,
      activateAccountStep: ActivateAccountStepsEnum.Introduction,
      forgotPasswordStep: ForgotPasswordStepsEnum.RequestCode,
    };
  }),
  on(actions.updateForgotPasswordStep, (state, action) => {
    return {
      ...state,
      forgotPasswordStep: action.step,
    };
  }),
  on(actions.logout, (state) => {
    return {
      ...initialState,
      currentUser: null,
    };
  }),
  on(actions.updateCurrentUser, (state, action) => {
    return {
      ...state,
      currentUser: action.user,
    };
  })
);

export function reducer(state: AuthState, action: Action) {
  return reducer(state, action);
}
