import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { TranslateService } from '@ngx-translate/core';
import { QuizService } from 'libs/services/quiz/quiz.service';
import { Quiz } from 'libs/types/quiz/quiz.type';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { MessageService } from 'primeng/api';
import { catchError, map, of, switchMap } from 'rxjs';
import * as actions from './quiz.actions';

@Injectable()
export class QuizEffects {
  loadActiveQuizCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadActiveQuizzesCount),
      switchMap(() =>
        this.service.getActiveQuizzesCount().pipe(
          map((count: number) => {
            return actions.loadActiveQuizzesCountSuccess({
              count,
            });
          }),
          catchError((error) => {
            return of(actions.loadActiveQuizzesCountError({ error }));
          })
        )
      )
    )
  );

  loadActiveQuizzes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadActiveQuizzes),
      switchMap(() =>
        this.service.getActiveQuizzes().pipe(
          map((quizzes: Quiz[]) => {
            return actions.loadActiveQuizzesSuccess({
              quizzes,
            });
          }),
          catchError((error) => {
            return of(actions.loadActiveQuizzesError({ error }));
          })
        )
      )
    )
  );

  loadQuizById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadQuiz),
      switchMap(({ id }) =>
        this.service.getQuizDetails(id).pipe(
          map((quiz: Quiz) => {
            return actions.loadQuizSuccess({
              quiz,
            });
          }),
          catchError((error) => {
            return of(actions.loadQuizError({ error }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private service: QuizService,
    private messageService: MessageService,
    private translate: TranslateService,
    private analyticsService: GoogleAnalyticsService
  ) {}
}
