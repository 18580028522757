import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { LocalStorageClass } from 'libs/shared/functions/local-storage.class';

@Injectable({
  providedIn: 'root',
})
export class LoginPageGuard {
  constructor(private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = LocalStorageClass.getToken();

    if (!token) return true;

    this.router.navigate(['']);

    return false;
  }
}
