<div class="activate-account-container">
  <ng-container [ngSwitch]="activeStep">
    <ng-container *ngSwitchCase="activateAccountStepsEnum.Introduction"
      ><div>
        {{ "login.createAccountMessage" | translate }}
      </div>
      <button
        pButton
        [label]="'login.letsGo' | translate"
        (click)="navigateToStep(activateAccountStepsEnum.RequestCode)"
      ></button>
    </ng-container>

    <ng-container *ngSwitchCase="activateAccountStepsEnum.RequestCode">
      <app-request-code-form
        [headerLabel]="'login.welcomeToJenz' | translate"
        [buttonLabel]="'login.requestActivationCode' | translate"
        (formSubmitted)="submitIntroductionForm($event)"
      ></app-request-code-form>
    </ng-container>

    <ng-container
      *ngSwitchCase="activateAccountStepsEnum.CodeReceivedConfirmation"
    >
      <div>
        {{ "login.activationCodeSentMessage" | translate }}
      </div>
      <span
        class="clickable-link clickable"
        (click)="
          navigateToStep(activateAccountStepsEnum.EnterCodeAndSetPassword)
        "
        >{{ "login.enterActivationCode" | translate }}</span
      >
      <div>{{ "login.codeNotReceived" | translate }}</div>
      <span
        class="clickable-link clickable"
        (click)="navigateToStep(activateAccountStepsEnum.RequestCode)"
        >{{ "login.resendCode" | translate }}</span
      >
    </ng-container>

    <ng-container
      *ngSwitchCase="activateAccountStepsEnum.EnterCodeAndSetPassword"
    >
      <app-set-password-form
        [headerlabel]="'login.activationAndPasswordSetup' | translate"
        [email]="email"
        (setPassword)="setUserPassword($event)"
      ></app-set-password-form>
    </ng-container>
  </ng-container>

  <p-button
    class="login-button"
    [label]="'login.alreadyHaveAccount' | translate"
    [text]="true"
    (click)="navigateBack()"
  ></p-button>
</div>
