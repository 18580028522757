import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Notification } from 'libs/types/notifications/notification.type';
import {
  NotificationsState,
  notificationsFeatureKey,
} from './notifications.reducer';

export const selectNotificationsState =
  createFeatureSelector<NotificationsState>(notificationsFeatureKey);

export const getNotificationsLoaded = createSelector(
  selectNotificationsState,
  (state) => state.notificationsLoaded
);

export const getNotifications = createSelector(
  selectNotificationsState,
  (state) => state.notifications
);

export const getNotificationsVisible = createSelector(
  selectNotificationsState,
  (state) => state.visible
);

export const getUnreadNotificationsCount = createSelector(
  selectNotificationsState,
  (state) => state.unreadNotificationsCount
);

export const getTodayNotifications = createSelector(
  getNotifications,
  (notifications: Notification[]) => {
    const today = new Date();

    return notifications.filter((notification) => {
      const notificationDate = new Date(notification.createdAt);

      return notificationDate.toDateString() === today.toDateString();
    });
  }
);

export const getYesterdayNotifications = createSelector(
  getNotifications,
  (notifications: Notification[]) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    return notifications.filter((notification) => {
      const notificationDate = new Date(notification.createdAt);

      return notificationDate.toDateString() === yesterday.toDateString();
    });
  }
);

export const getEarlierNotifications = createSelector(
  getNotifications,
  (notifications: Notification[]) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    return notifications.filter((notification) => {
      const notificationDate = new Date(notification.createdAt);

      return (
        notificationDate.toDateString() !== today.toDateString() &&
        notificationDate.toDateString() !== yesterday.toDateString()
      );
    });
  }
);
