import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponseSuccess } from 'libs/types/core';
import { IQuizResult } from 'libs/types/quiz/interfaces/quiz-result.interace';
import { IQuiz } from 'libs/types/quiz/interfaces/quiz.interface';
import { QuizQuestion } from 'libs/types/quiz/quiz-question.type';
import { QuizResult } from 'libs/types/quiz/quiz-result.type';
import { Quiz } from 'libs/types/quiz/quiz.type';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { endpoints } from './quiz.endpoints';

@Injectable({
  providedIn: 'root',
})
export class QuizService {
  baseApiRoute = environment.baseApiRoute;

  constructor(private http: HttpClient) {}

  getActiveQuizzes() {
    const url = `${this.baseApiRoute}${endpoints.getActiveQuizzes}`;

    let params = new HttpParams();
    params = params.append('page[limit]', 100);
    params = params.append('page[offset]', 0);

    return this.http
      .get<ApiResponseSuccess<IQuiz[]>>(url, { params })
      .pipe(map((response) => response.data.map((data) => new Quiz(data))));
  }

  getActiveQuizzesCount() {
    const url = `${this.baseApiRoute}${endpoints.getActiveQuizzes}`;

    let params = new HttpParams();
    params = params.append('page[limit]', 0);
    params = params.append('page[offset]', 0);

    return this.http
      .get<ApiResponseSuccess<IQuiz[]>>(url, { params })
      .pipe(map((response) => response.meta.page.total));
  }

  getQuizDetails(quizId: any) {
    const url = `${this.baseApiRoute}${endpoints.getQuizDetails}`.replace(
      '{quizId}',
      quizId
    );

    return this.http.get<ApiResponseSuccess<IQuiz>>(url).pipe(
      map((response) => {
        let questions = response.included
          .filter((x) => x.type === 'quiz_question')
          ?.map((question) => new QuizQuestion(question));

        return new Quiz(response.data, questions);
      })
    );
  }

  submitAnswers(quizId, data) {
    const url = `${this.baseApiRoute}${endpoints.submitAnswers}`.replace(
      '{quizId}',
      quizId
    );

    return this.http
      .post<ApiResponseSuccess<IQuizResult>>(url, { data })
      .pipe(map((result) => new QuizResult(result.data.attributes)));
  }
}
