import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-select-language',
  templateUrl: './select-language.component.html',
  styleUrl: './select-language.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectLanguageComponent {
  selectedLanguage = '';

  languages = [
    {
      label: 'English',
      value: 'en',
    },
    // {
    //   label: 'Български',
    //   value: 'bg',
    // },
    {
      label: 'Deutsch',
      value: 'de',
    },
    {
      label: 'Hrvatski',
      value: 'hr',
    },
    {
      label: 'Italiano',
      value: 'it',
    },
    // {
    //   label: 'Македонски',
    //   value: 'mk',
    // },
    {
      label: 'Português',
      value: 'pt',
    },
    // {
    //   label: 'Slovenský',
    //   value: 'sk',
    // },
    {
      label: 'Slovenščina',
      value: 'sl',
    },
  ];

  @Output() languageSelected = new EventEmitter<string>();

  selected(language: string) {
    this.languageSelected.emit(language);
  }
}
