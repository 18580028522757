import { createFeatureSelector, createSelector } from '@ngrx/store';
import { QuizState, quizFeatureKey } from './quiz.reducer';

export const selectQuizState = createFeatureSelector<QuizState>(quizFeatureKey);

export const getQuizDialogVisible = createSelector(
  selectQuizState,
  (state) => state.dialogVisible
);

export const getQuizzes = createSelector(
  selectQuizState,
  (state) => state.active
);

export const getNumberOfActiveQuizzes = createSelector(
  selectQuizState,
  (state) => state.activeCount
);

export const getCurrentQuiz = createSelector(
  selectQuizState,
  (state) => state?.current
);

export const getQuizSidebarActive = createSelector(
  selectQuizState,
  (state) => !!state?.current
);

export const getQuizzesLoaded = createSelector(
  selectQuizState,
  (state) => state?.loaded
);
