import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LocalStorageClass } from 'libs/shared/functions/local-storage.class';
import { User } from 'libs/types/auth';
import { environment } from 'src/environments/environment';
import * as fromAuth from './auth.reducer';

export const selectAuthState = createFeatureSelector<fromAuth.AuthState>(
  fromAuth.authFeatureKey
);

export const getAuthStep = createSelector(
  selectAuthState,
  (state: fromAuth.AuthState) => state.authStep
);

export const getActivateAccountStep = createSelector(
  selectAuthState,
  (state: fromAuth.AuthState) => state.activateAccountStep
);

export const getForgotPasswordStep = createSelector(
  selectAuthState,
  (state: fromAuth.AuthState) => state.forgotPasswordStep
);

export const getCurrentUser = createSelector(
  selectAuthState,
  (state: fromAuth.AuthState) => state.currentUser
);

export const getCurrentUserId = createSelector(
  getCurrentUser,
  (currentUser: User) => currentUser.id
);

export const getIsAuth = createSelector(getCurrentUser, (user: User) => !!user);

export const getCurrentUserGroups = createSelector(
  getCurrentUser,
  (user: User) => user?.groups
);

export const getCurrentUserProfileImage = createSelector(
  getCurrentUser,
  (user: User) => {
    if (!user?.profileImage?.length) return '';

    const attachmentRoute = environment.attachmentRoute;
    const token = LocalStorageClass.getToken();
    return `${attachmentRoute}${user.profileImage}?jwt=${token}&v=extrasmall`;
  }
);

export const getIsAdmin = createSelector(
  getCurrentUser,
  (user: User) => user.role === 'ROLE_ADMIN'
);

export const getIsAdminOrHasCompanyProfile = createSelector(
  getCurrentUser,
  (user: User) => user.hasCompanyProfile || user.role === 'ROLE_ADMIN'
);
