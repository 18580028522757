<p-dialog
  [header]="'quiz.quizzes' | translate"
  [(visible)]="visible"
  (onHide)="hideDialog()"
  (onShow)="dialogOpened()"
  [modal]="true"
  [draggable]="false"
  styleClass="surveys-dialog"
>
  <ng-template pTemplate="body">
    <div class="surveys-list">
      <ng-container *ngIf="loaded; else showSpinner">
        <ng-container *ngIf="quizzes?.length; else noDataMessage">
          <ng-container *ngFor="let quiz of quizzes">
            <div
              class="survey-list-item clickable"
              (click)="quizSelected(quiz.id)"
            >
              <div class="survey-title">{{ quiz.title }}</div>
              <div class="survey-info">
                <i class="pi pi-clock"></i>
                <ng-container *ngIf="quiz?.timeLimit; else noLimit">
                  {{
                    "quiz.timeLimit" | translate : { minutes: quiz?.timeLimit }
                  }}
                </ng-container>
                <ng-template #noLimit>
                  {{ "quiz.noTimeLimit" | translate }}
                </ng-template>
              </div>
              <div class="survey-info">
                <i class="pi pi-hourglass"></i>
                {{ "quiz.endsAt" | translate }}
                {{ quiz.endsAt | localizedDate : "d MMMM y hh:mm" }}
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>
</p-dialog>

<ng-template #showSpinner>
  <div class="spinner-container">
    <p-progressSpinner></p-progressSpinner>
  </div>
</ng-template>

<ng-template #noDataMessage>
  {{ "quiz.noActive" | translate }}
</ng-template>
