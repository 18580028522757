<div class="container">
  <div class="form-container">
    <img src="assets/images/jenz-logo.png" class="login-jenz-logo" />
    <ng-container [ngSwitch]="authStep$ | async">
      <ng-container *ngSwitchCase="visibleFormEnum.login"
        ><app-login
          (login)="login($event)"
          (activateAccountClicked)="
            navigateToForm(visibleFormEnum.activateAccount)
          "
          (forgotPasswordClicked)="navigateToForm(visibleFormEnum.setPassword)"
        ></app-login
      ></ng-container>
      <ng-container *ngSwitchCase="visibleFormEnum.activateAccount"
        ><app-activate-account
          [activeStep]="activeAccountStep$ | async"
          (updateStep)="updateActivateAccountStep($event)"
          (navigateToLogin)="navigateToForm(visibleFormEnum.login)"
          (requestActivationCode)="requestActivationCode($event)"
          (setPassword)="setPassword($event)"
        ></app-activate-account
      ></ng-container>
      <ng-container *ngSwitchCase="visibleFormEnum.setPassword"
        ><app-forgot-password
          [activeStep]="forgotPasswordStep$ | async"
          (navigateToLogin)="navigateToForm(visibleFormEnum.login)"
          (updateStep)="updateForgotPasswordStep($event)"
          (setPassword)="resetPassword($event)"
          (requestActivationCode)="requestResetPasswordActivationCode($event)"
        ></app-forgot-password
      ></ng-container>
    </ng-container>
  </div>
</div>
