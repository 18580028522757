import { User } from 'libs/types/auth';

export class LocalStorageClass {
  // Token
  static getToken() {
    return localStorage.getItem('token');
  }

  static getRefreshToken() {
    return localStorage.getItem('refreshToken');
  }

  static setToken(token: string, refreshToken?: string) {
    localStorage.setItem('token', token);
    localStorage.setItem('setTokenDate', new Date().toString());

    if (refreshToken?.length)
      localStorage.setItem('refreshToken', refreshToken);
  }

  static setRefreshToken(refreshToken: string) {
    localStorage.setItem('refreshToken', refreshToken);
  }

  // Set token date
  static getTokenSetDate() {
    return localStorage.getItem('setTokenDate');
  }

  // Selected language
  static getSelectedLanguage() {
    return localStorage.getItem('selected-language') ?? 'en';
  }

  static setSelectedLanguage(language: string) {
    localStorage.setItem('selected-language', language);
  }

  // Current user
  static getCurrentUser(): User {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  static setCurrentUser(user: User) {
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  static clearCurrentUserData() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    localStorage.removeItem('setTokenDate');
    localStorage.removeItem('refreshToken');
  }

  // Terms and conditions
  static acceptTermsAndConditions() {
    localStorage.setItem('termsAndConditionsAccepted', 'true');
  }

  static getTermsAndConditionsAccepted() {
    return localStorage.getItem('termsAndConditionsAccepted') === 'true';
  }
}
