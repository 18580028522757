import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthState } from 'libs/states/auth';
import * as authActions from 'libs/states/auth/+state/auth.actions';
import { SetPasswordData } from 'libs/types/auth';
import { map } from 'rxjs';

@Component({
  selector: 'app-activate-account-container',
  templateUrl: './activate-account-container.component.html',
  styleUrl: './activate-account-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivateAccountContainerComponent {
  inviterEmail = '';

  urlParams$ = this.activatedRoute.queryParams.pipe(
    map((data: any) => {
      if (!data?.code?.length || !data?.userEmail?.length) {
        this.router.navigate(['login']);
        return null;
      }

      if (data?.inviter) this.inviterEmail = data?.inviter;

      return data;
    })
  );

  constructor(
    private activatedRoute: ActivatedRoute,
    private authState: Store<AuthState>,
    private router: Router
  ) {}

  passwordSet(data: SetPasswordData) {
    // Add inviter email somewhere

    this.authState.dispatch(authActions.setPassword({ data }));
  }
}
