import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SetPasswordData } from 'libs/types/auth';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-set-password-form',
  templateUrl: './set-password-form.component.html',
  styleUrls: ['./set-password-form.component.scss'],
})
export class SetPasswordFormComponent implements OnInit {
  showPassword = false;
  showConfirmPassword = false;

  @Input() email = '';
  @Input() headerlabel = '';
  @Input() code = '';

  @Output() setPassword = new EventEmitter<SetPasswordData>();

  setPasswordForm = this.fb.group({
    activationCode: ['', [Validators.required, Validators.minLength(5)]],
    password: ['', [Validators.required]],
    confirmPassword: ['', [Validators.required]],
  });

  get formValue() {
    return this.setPasswordForm.value;
  }

  get passwordType() {
    return this.showPassword ? 'text' : 'password';
  }

  get confirmPasswordType() {
    return this.showConfirmPassword ? 'text' : 'password';
  }

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    if (!this.code?.length) return;

    const codeControl = this.setPasswordForm.get('activationCode');

    codeControl.patchValue(this.code);
    codeControl.disable();
    codeControl.updateValueAndValidity();
  }

  submit() {
    if (this.setPasswordForm.invalid) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill all fields',
      });

      return;
    }

    if (this.formValue.password !== this.formValue.confirmPassword) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Make sure your passwords match!',
      });

      return;
    }

    const data = {
      email: this.email,
      token: this.code?.length > 0 ? this.code : this.formValue.activationCode,
      password: this.formValue.password,
    };

    this.setPassword.emit(data);
  }

  viewPassword() {
    this.showPassword = !this.showPassword;
  }

  viewConfirmPassword() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }
}
