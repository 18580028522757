import { createAction, props } from '@ngrx/store';
import { Quiz } from 'libs/types/quiz/quiz.type';

export const loadQuiz = createAction(
  '[Quiz] Load Quiz',
  props<{ id: number }>()
);

export const loadQuizSuccess = createAction(
  '[Quiz] Load Quiz Success',
  props<{ quiz: Quiz }>()
);

export const loadQuizError = createAction(
  '[Quiz] Load Quiz Error',
  props<{ error: any }>()
);

export const loadActiveQuizzes = createAction('[Quiz] Load Active Quiz');

export const loadActiveQuizzesSuccess = createAction(
  '[Quiz] Load Active Quiz Success',
  props<{ quizzes: Quiz[] }>()
);

export const loadActiveQuizzesError = createAction(
  '[Quiz] Load Active Quiz Error',
  props<{ error: any }>()
);

export const loadActiveQuizzesCount = createAction(
  '[Quiz] Load Active Quiz Count'
);

export const loadActiveQuizzesCountSuccess = createAction(
  '[Quiz] Load Active Quiz Count Success',
  props<{ count: number }>()
);

export const loadActiveQuizzesCountError = createAction(
  '[Quiz] Load Active Quiz Count Error',
  props<{ error: any }>()
);

export const toggleQuizDialog = createAction(
  '[Quiz] Toggle Quiz Dialog',
  props<{ visible: boolean }>()
);

export const clearCurrentQuiz = createAction('[Quiz] Clear Current Quiz');

export const QuizAnsweredSuccess = createAction('[Quiz] Quiz Answered Success');
