import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { LocalStorageClass } from 'libs/shared/functions/local-storage.class';

@Component({
  selector: 'app-terms-and-conditions-dialog',
  templateUrl: './terms-and-conditions-dialog.component.html',
  styleUrl: './terms-and-conditions-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class TermsAndConditionsDialogComponent {
  selectedLanguage = LocalStorageClass.getSelectedLanguage();
}
