import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Router } from '@angular/router';
import {
  ActivateAccountStepsEnum,
  ForgotPasswordStepsEnum,
} from 'libs/enums/auth';
import { AuthService } from 'libs/services/auth';
import { LocalStorageClass } from 'libs/shared/functions/local-storage.class';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { MessageService } from 'primeng/api';
import { of } from 'rxjs';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';
import * as authActions from './auth.actions';

@Injectable()
export class AuthEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.login),
      switchMap(({ loginData }) =>
        this.service.login(loginData).pipe(
          map((response) => {
            LocalStorageClass.setToken(response.token, response.refreshToken);
            return authActions.loginSuccess();
          }),
          catchError((err) => of(authActions.loginError()))
        )
      )
    )
  );

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.loginSuccess),
      switchMap(() =>
        this.service.getLoggedUserData().pipe(
          map((user) => {
            this.analyticsService.event('user-logged-in');
            this.messageService.clear();
            LocalStorageClass.setCurrentUser(user);
            return authActions.loginSetCurrentUser({ user });
          }),
          catchError((err) => of(authActions.loginError()))
        )
      )
    )
  );

  loginSetCurrentUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.loginSetCurrentUser),
        tap(() => {
          this.router.navigate(['./..']);
        })
      ),
    { dispatch: false }
  );

  getLoggedUsersData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.getLoggedUsersData),
      switchMap(() =>
        this.service.getLoggedUserData().pipe(
          tap((user) => {
            this.analyticsService.event(`user_audience_${user.tenant}`);
          }),
          tap((user) => {
            LocalStorageClass.setCurrentUser(user);
          }),
          map((user) => {
            return authActions.setCurrentUser({ user });
          })
        )
      )
    )
  );

  loginError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.loginError),
        tap(() => {
          this.messageService.add({
            severity: 'error',
            summary: 'Login failed',
            detail: 'Invalid credentials',
          });
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.logout),
        tap(() => {
          LocalStorageClass.clearCurrentUserData();
          this.router.navigate(['login']).then(() => window.location.reload());
        })
      ),
    { dispatch: false }
  );

  requestActivationCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.requestActivationCode),
      switchMap(({ email }) =>
        this.service.requestActivationCode(email).pipe(
          map((response) => {
            this.analyticsService.event('user-code-request');
            this.updateActivateAccountStep(
              ActivateAccountStepsEnum.CodeReceivedConfirmation
            );
            return authActions.requestActivationCodeSuccess();
          }),
          catchError((err) => of(authActions.requestActivationCodeError()))
        )
      )
    )
  );

  requestActivationCodeError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.requestActivationCodeError),
        tap(() => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error occured on generate',
          });
        })
      ),
    { dispatch: false }
  );

  requestResetPasswordCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.requestResetPasswordCode),
      switchMap(({ email }) =>
        this.service.requestActivationCode(email).pipe(
          map((response) => {
            this.analyticsService.event('user-reset-password-code-request');
            this.updateForgotPasswordStep(
              ForgotPasswordStepsEnum.CodeReceivedConfirmation
            );
            return authActions.requestResetPasswordCodeSuccess();
          }),
          catchError((err) => of(authActions.requestResetPasswordCodeError()))
        )
      )
    )
  );

  requestResetPasswordCodeError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.requestResetPasswordCodeError),
        tap(() => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error occured on generate',
          });
        })
      ),
    { dispatch: false }
  );

  setPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.setPassword),
      switchMap(({ data }) =>
        this.service.setPassword(data).pipe(
          map(() => {
            const loginData = {
              email: data.email,
              password: data.password,
            };

            this.analyticsService.event('set-password-success');
            return authActions.setPasswordSuccess({ loginData });
          }),
          catchError((err) => of(authActions.setPasswordError()))
        )
      )
    )
  );

  setPasswordSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.setPasswordSuccess),
      map(({ loginData }) => authActions.login({ loginData }))
    )
  );

  setPasswordError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.setPasswordError),
        tap(() => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error occured while setting password',
          });
        })
      ),
    { dispatch: false }
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.resetPassword),
      switchMap(({ data }) =>
        this.service.setPassword(data).pipe(
          map(() => {
            const loginData = {
              email: data.email,
              password: data.password,
            };

            this.analyticsService.event('reset-password-success');

            return authActions.resetPasswordSuccess({ loginData });
          }),
          catchError((err) => of(authActions.resetPasswordError()))
        )
      )
    )
  );

  resetPasswordSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.resetPasswordSuccess),
      map(({ loginData }) => authActions.login({ loginData }))
    )
  );

  resetPasswordError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.resetPasswordError),
        tap(() => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error occured while resetting password',
          });
        })
      ),
    { dispatch: false }
  );

  updateCurrentUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.updateCurrentUser),
        tap((action) => {
          LocalStorageClass.setCurrentUser(action.user);
        })
      ),
    { dispatch: false }
  );

  refreshCurrentToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.refreshCurrentToken),
      switchMap(() => this.service.refreshCurrentToken()),
      tap((response: any) => {
        LocalStorageClass.setToken(response?.token);
      }),
      map(() => authActions.refreshCurrentTokenSuccess()),
      catchError((err) => {
        return of(authActions.logout()).pipe(delay(1500));
      })
    )
  );

  updateAuthStep$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.updateAuthStep),
        tap((action) => {
          switch (action.step) {
            case 2:
              this.updateActivateAccountStep(1);
              break;
            case 3:
              this.updateForgotPasswordStep(1);
              break;
            default:
              this.router.navigate(['login']);
              break;
          }
        })
      ),
    { dispatch: false }
  );

  updateActivateAccountStep$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.updateActivateAccountStep),
        tap((action) => {
          this.updateActivateAccountStep(action?.step);
        })
      ),
    { dispatch: false }
  );

  updateForgotPasswordStep$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.updateForgotPasswordStep),
        tap((action) => {
          this.updateForgotPasswordStep(action?.step);
        })
      ),
    { dispatch: false }
  );

  clearCache$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.refreshCurrentTokenSuccess, authActions.logout),
        switchMap(() => window.caches.keys()),
        map((keys) => keys?.map((key) => window.caches.delete(key))),
        tap(() =>
          navigator.serviceWorker
            .getRegistrations()
            .then((registrations) =>
              registrations.map((registration) => registration.unregister())
            )
        )
      ),
    { dispatch: false }
  );

  updateForgotPasswordStep(step: number) {
    this.router.navigate(['login', { forgotPasswordStep: step }]);
  }

  updateActivateAccountStep(step: number) {
    this.router.navigate(['login', { activateAccountStep: step }]);
  }

  constructor(
    private actions$: Actions,
    private service: AuthService,
    private router: Router,
    private messageService: MessageService,
    private analyticsService: GoogleAnalyticsService
  ) {}
}
