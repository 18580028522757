<div class="container" [style]="{ height: height }">
  <div #backdrop class="backdrop" [style]="{ height: height }">
    <div class="highlights" [innerHTML]="highlightedText"></div>
  </div>
  <span class="p-float-label {{ textAreaKey }}">
    <textarea
      [(ngModel)]="textValue"
      (ngModelChange)="valueUpdated($event)"
      (scroll)="handleScroll()"
      #textarea
      pInputTextarea
      [mention]="mentionItems"
      [mentionConfig]="{
        labelKey: 'tagName',
        disableSearch: true,
        dropUp: showMentionsAbove
      }"
      [mentionListTemplate]="mentionListTemplate"
      [style]="{ height: height }"
      (opened)="mentionSearchChanged('')"
      (searchTerm)="mentionSearchChanged($event)"
      (itemSelected)="mentionItemSelected($event)"
    ></textarea>
    <label class="text-area-label">{{ label }}</label>
  </span>
</div>

<ng-container
  *ngIf="{ loadMentionOptions: loadMentionOptions$ | async } as data"
></ng-container>

<ng-template #mentionListTemplate let-item="item">
  <div
    class="select-mention-user"
    [ngClass]="{ 'user-inactive': item.inactive }"
  >
    <ng-container *ngIf="item?.profileImage; else noImage"
      ><img
        class="user-avatar"
        [src]="
          env.attachmentRoute +
          item.profileImage +
          '?jwt=' +
          token +
          '&v=extrasmall'
        "
        onError="this.src='assets/icons/user-avatar.svg'"
    /></ng-container>
    <ng-template #noImage
      ><img class="user-avatar" src="assets/icons/user-avatar.svg"
    /></ng-template>
    <span
      >{{ item.name }}
      <ng-container *ngIf="item.inactive">(Inactive)</ng-container></span
    >
  </div>
</ng-template>
