import { Action, createReducer, on } from '@ngrx/store';
import { Quiz } from 'libs/types/quiz/quiz.type';
import * as actions from './quiz.actions';

export const quizFeatureKey = 'quiz';

export interface QuizState {
  active: Quiz[];
  activeCount: number;
  dialogVisible: boolean;
  current?: Quiz;
  loaded: boolean;
}

export const initialState: QuizState = {
  active: [],
  activeCount: 0,
  dialogVisible: false,
  loaded: false,
};

export const quizReducer = createReducer(
  initialState,
  on(actions.loadActiveQuizzesSuccess, (state, action) => {
    return {
      ...state,
      active: action.quizzes,
      loaded: true,
    };
  }),
  on(actions.loadActiveQuizzesCountSuccess, (state, action) => {
    return {
      ...state,
      activeCount: action.count,
    };
  }),
  on(actions.toggleQuizDialog, (state, action) => {
    return {
      ...state,
      dialogVisible: action.visible,
      loaded: false,
    };
  }),
  on(actions.loadQuizSuccess, (state, action) => {
    return {
      ...state,
      current: action.quiz,
    };
  }),
  on(actions.clearCurrentQuiz, (state) => {
    const { current, ...restOfState } = state;

    return {
      ...restOfState,
    };
  })
);

export function reducer(state: QuizState, action: Action) {
  return reducer(state, action);
}
