import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageClass } from 'libs/shared/functions/local-storage.class';
import {
  LoginData,
  LoginResponse,
  SetPasswordData,
  User,
} from 'libs/types/auth';
import { IUser } from 'libs/types/auth/interfaces/user.interface';
import { ITenantConfiguration } from 'libs/types/config/interfaces/tenant-configuration.interface';
import { TenantConfiguration } from 'libs/types/config/tenant-configuration.type';
import { ApiResponseSuccess } from 'libs/types/core';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, catchError, map, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { endpoints } from './auth.endpoints';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  baseUrl = environment.baseApiRoute;

  configurationSubject = new BehaviorSubject<TenantConfiguration>(null);
  configuration$ = this.configurationSubject.asObservable();

  constructor(
    private http: HttpClient,
    private messageService: MessageService
  ) {}

  login(data: LoginData) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-type': 'application/json',
    });
    const url = `${this.baseUrl}${endpoints.login}`;
    return this.http.post<LoginResponse>(url, data, { headers });
  }

  getLoggedUserData() {
    const url = `${this.baseUrl}${endpoints.loggedUserData}`;

    return this.http
      .get<any>(url) // UPDATE TYPE
      .pipe(map((response) => new User(response?.data)));
  }

  requestActivationCode(email: string) {
    const url = `${this.baseUrl}${endpoints.requestActivationCode}`;

    return this.http.post<any>(url, { email }); // UPDATE TYPE;
  }

  setPassword(data: SetPasswordData) {
    const url = `${this.baseUrl}${endpoints.setPassword}`.replace(
      '{token}',
      data.token
    );

    return this.http.post<any>(url, data); // UPDATE TYPE;
  }

  getGlobalApplicationConfiguration() {
    const url = `${this.baseUrl}${endpoints.globalApplicationConfiguration}`;

    return this.http.get<ApiResponseSuccess<ITenantConfiguration>>(url).pipe(
      map((response) => new TenantConfiguration(response.data)),
      tap((config) => this.configurationSubject.next(config))
    );
  }

  refreshCurrentToken() {
    const url = `${this.baseUrl}${endpoints.refreshToken}`;

    const refreshToken = LocalStorageClass.getRefreshToken();

    const data = {
      refreshToken,
    };

    return this.http.post(url, data);
  }

  getMissingRefreshToken() {
    const url = `${this.baseUrl}${endpoints.refreshToken}`;

    return this.http.get(url).pipe(
      tap((response: any) => {
        if (!response?.refreshToken) return;

        LocalStorageClass.setRefreshToken(response?.refreshToken);
      })
    );
  }

  registerNewAccount(attributes) {
    const url = `${this.baseUrl}${endpoints.registration}`;

    const data = {
      type: 'user-registration',
      attributes,
    };

    return this.http.post<ApiResponseSuccess<IUser>>(url, { data }).pipe(
      map((response) => new User(response.data)),
      catchError((error) => {
        const detail = error?.error?.errors[0]?.title;

        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail,
        });
        return of(null);
      })
    );
  }
}
