<ng-container
  *ngIf="termsAndConditionsAccepted$ | async; else termsAndConditionsDialog"
>
  <ng-container *ngIf="pageReady$ | async; else showJenzLoading">
    <app-layout></app-layout>
  </ng-container>

  <ng-template #showJenzLoading>
    <div class="main-spinner-container">
      <p-progressSpinner
        strokeWidth="3"
        styleClass="whole-page-spinner"
        animationDuration="5s"
      ></p-progressSpinner>
      <img src="assets/images/jenz-logo.png" class="spinner-jenz-logo" />
    </div>
  </ng-template>

  <p-toast></p-toast>
</ng-container>

<ng-template #termsAndConditionsDialog>
  <p-dialog
    [modal]="true"
    [closable]="false"
    [visible]="true"
    [draggable]="false"
    styleClass="terms-and-conditions-dialog"
  >
    <ng-container *ngIf="!selectedLanguage; else privacyPolicy">
      <app-select-language
        (languageSelected)="selectLanguage($event)"
      ></app-select-language>
      <p-footer>
        <div class="next-button-area">
          <button
            pButton
            [label]="'general.next' | translate"
            class="next-button"
            (click)="languageSelected()"
          ></button>
        </div>
      </p-footer>
    </ng-container>
    <ng-template #privacyPolicy>
      <app-terms-and-conditions-dialog
        (termsAndConditionsAccepted)="acceptTermsAndConditions()"
      ></app-terms-and-conditions-dialog>
      <p-footer>
        <div class="confirm-area">
          <h3 class="confirmation-text">
            I have read and accept privacy policy
          </h3>
          <button
            pButton
            label="Confirm"
            class="confirmation-button"
            (click)="acceptTermsAndConditions()"
          ></button>
        </div>
      </p-footer>
    </ng-template>
  </p-dialog>
</ng-template>
