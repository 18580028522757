<ng-container *ngIf="urlParams$ | async"></ng-container>

<div class="container">
  <div class="form-container">
    <img src="assets/images/jenz-logo.png" class="login-jenz-logo" />

    @if (registrationSuccessful$ | async) {
    <div class="registration-container">
      <h2>{{ "registration.registrationSuccessfullText" | translate }}</h2>
      <h3>
        {{ "registration.additionalQuestions" | translate }}
        <a href="mailto:support@jenz.app">support{{ "@" }}jenz.app</a>
      </h3>

      <h3 class="hide-on-mobile">
        {{ "registration.inTheMeantime" | translate }}
      </h3>
      <img class="qr-code hide-on-mobile" src="assets/images/download-qr.png" />

      <h3 class="show-on-mobile">
        {{ "registration.click" | translate }}
        <a href="https://onelink.to/jenz.app">{{
          "registration.here" | translate
        }}</a>
        {{ "registration.toDownloadJenzApp" | translate }}
      </h3>
    </div>
    } @else {

    <form [formGroup]="registrationForm">
      <div class="registration-form">
        <h3 class="full-width">{{ "registration.welcome" | translate }}</h3>
        <h4 class="full-width">
          {{ "registration.registration" | translate }}
        </h4>

        <span class="p-float-label full-width">
          <input pInputText formControlName="token" class="full-width" />
          <label>{{ "registration.token" | translate }}</label>
        </span>

        <span class="p-float-label full-width">
          <input pInputText formControlName="firstName" class="full-width" />
          <label>{{ "registration.firstName" | translate }}</label>
        </span>

        <span class="p-float-label full-width">
          <input pInputText formControlName="lastName" class="full-width" />
          <label>{{ "registration.lastName" | translate }}</label>
        </span>

        <span class="p-float-label full-width">
          <input pInputText formControlName="email" class="full-width" />
          <label>{{ "registration.emailOrPhone" | translate }}</label>
        </span>

        <span class="p-input-icon-right p-float-label full-width">
          <input [type]="passwordType" pInputText formControlName="password" />
          <i class="pi pi-eye-slash clickable" (click)="viewPassword()"></i>
          <label>{{ "registration.password" | translate }}</label>
        </span>
        <span class="p-input-icon-right p-float-label full-width">
          <input
            [type]="confirmPasswordType"
            pInputText
            formControlName="confirmPassword"
          />
          <i
            class="pi pi-eye-slash clickable"
            (click)="viewConfirmPassword()"
          ></i>
          <label>{{ "registration.confirmPassword" | translate }}</label>
        </span>
        <button
          pButton
          type="password"
          class="full-width"
          [label]="'registration.startJenzing' | translate"
          (click)="submit()"
        ></button>
      </div>
    </form>
    }
  </div>
</div>

<p-toast></p-toast>
