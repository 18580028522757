import { INotificationData } from './interfaces/notification.interface';

export class Notification {
  id: number;
  clap?: {
    id?: number;
    note?: string;
    giverFirstName?: string;
    giverLastName?: string;
    giverProfilePicture?: string;
  };
  commentContent: string;
  commentId: number;
  commentLikeCount: 0;
  createdAt: Date;
  fromUser: string;
  fromUserId: number;
  isRead: boolean;
  message: string;
  storyData?: {
    storyId?: number;
    storyCommentCount?: number;
    storyLikeCount?: number;
    media?: any;
  };
  type: string;
  userCommentCount: number;

  constructor(data: INotificationData) {
    this.id = data.attributes.id;
    this.commentContent = data.attributes.comment_content;
    this.commentId = data.attributes.comment_id;
    this.commentLikeCount = data.attributes.comment_like_count;
    this.createdAt = data.attributes.created_at;
    this.fromUser = data.attributes.from_user;
    this.fromUserId = data.attributes.from_user_id;
    this.isRead = data.attributes.is_read;
    this.message = data.attributes.message;
    this.storyData = data.attributes.story_data;
    this.type = data.attributes.type;
    this.userCommentCount = data.attributes.user_comment_count;

    if (data.attributes.clap_data) {
      const clapData = data.attributes.clap_data;

      this.clap = {
        id: clapData.id,
        note: clapData.note,
        giverFirstName: clapData.giver_first_name,
        giverLastName: clapData.giver_last_name,
        giverProfilePicture: clapData.giver_last_name,
      };
    }

    if (data.attributes.story_data) {
      const storyData = data.attributes.story_data;

      this.storyData = {
        storyId: storyData.story_id,
        storyCommentCount: storyData.story_comment_count,
        storyLikeCount: storyData.story_like_count,
        media: storyData.media,
      };
    }
  }
}
