import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UsersService } from 'libs/services/users';
import { LocalStorageClass } from 'libs/shared/functions/local-storage.class';
import { Subject, debounceTime, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-highlight-textarea',
  templateUrl: './highlight-textarea.component.html',
  styleUrls: ['./highlight-textarea.component.scss'],
})
export class HighlightTextareaComponent implements OnChanges {
  token = LocalStorageClass.getToken();
  env = environment;
  highlightedUsers = [];

  @ViewChild('backdrop') $backdrop: ElementRef<HTMLDivElement>;
  @ViewChild('textarea') $textarea: ElementRef<HTMLTextAreaElement>;

  @Input() label = '';
  @Input() textValue = '';
  @Input() showMentionsAbove = true;
  @Input() textAreaKey = '';
  @Input() height = '200px';

  @Output() textChanged = new EventEmitter<string>();
  @Output() mentionedUsersIdsChanged = new EventEmitter<string[]>();

  get highlightedText() {
    return this.applyHighlights(this.textValue);
  }

  constructor(private usersService: UsersService) {}

  applyHighlights(text) {
    text = text ? text.replace(/\n$/g, '\n\n') : '';

    const uniqueHighlightedUsers = [...new Set(this.highlightedUsers)];

    uniqueHighlightedUsers.forEach((x) => {
      text = text.replace(new RegExp(x, 'g'), '<mark>$&</mark>');
    });
    return text;
  }

  handleScroll() {
    var scrollTop = this.$textarea.nativeElement.scrollTop;
    this.$backdrop.nativeElement.scrollTop = scrollTop;

    var scrollLeft = this.$textarea.nativeElement.scrollLeft;
    this.$backdrop.nativeElement.scrollLeft = scrollLeft;
  }

  valueUpdated(textAreaValue) {
    let value = textAreaValue;
    let valuesToReplace = [];

    if (this.selectedMentionedUsers.length) {
      this.selectedMentionedUsers.map((mentionedUser) => {
        const valueToReplace = {
          valueToReplace: `@${mentionedUser.tagName}`,
          newValue: `[@${mentionedUser.tagName}:${mentionedUser.id}]`,
        };

        valuesToReplace = [...valuesToReplace, valueToReplace];
      });
    }

    if (this.loadedMentionedUsers.length) {
      this.loadedMentionedUsers.map((mentionedUser) => {
        const valueToReplace = {
          valueToReplace: mentionedUser.mentionUsername,
          newValue: mentionedUser.mention,
        };

        valuesToReplace = [...valuesToReplace, valueToReplace];
      });
    }

    const uniqueValues = [
      ...new Map(
        valuesToReplace.map((item) => [item?.valueToReplace, item])
      ).values(),
    ];

    if (uniqueValues?.length)
      uniqueValues.map((x) => {
        value = value.replaceAll(x.valueToReplace, x.newValue);
      });

    this.textChanged.emit(value);
    this.emitMentionedUsersIds(value);
  }

  // Mentions

  loadedMentionedUsers = [];
  mentionOptions = [];
  selectedMentionedUsers = [];

  get mentionItems() {
    return this.mentionOptions.map((x) => {
      return {
        tagName: x.tagName.replaceAll(' ', ''),
        name: x.name,
        id: x.id,
        profileImage: x.profileImage,
        inactive: !x.lastActivity?.date,
      };
    });
  }

  loadMentionOptions = new Subject<string>();
  loadMentionOptions$ = this.loadMentionOptions.asObservable().pipe(
    debounceTime(300),
    switchMap((searchValue) => this.usersService.loadUsers(5, 0, searchValue)),
    tap((data) => (this.mentionOptions = data.data))
  );

  mentionSearchChanged(text): void {
    this.loadMentionOptions.next(text);
  }

  mentionItemSelected(user): void {
    const mentionedUsers = this.selectedMentionedUsers.filter(
      (x) => x.id !== user.id
    );

    this.selectedMentionedUsers = [...mentionedUsers, user];

    this.highlightedUsers = [...this.highlightedUsers, `@${user.tagName}`];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['textValue']) {
      const textValue = changes['textValue'].currentValue;

      if (!textValue?.length) return;

      const regex = /\[@[^\s]+:\d+\]/g;

      const matches = textValue.matchAll(regex);

      if (!matches) return;

      for (const match of matches) {
        const mention = match[0] as string;

        const usernameEnd = mention.indexOf(':');

        const mentionUsername = mention.slice(1, usernameEnd);

        this.highlightedUsers = [...this.highlightedUsers, mentionUsername];

        this.textValue = this.textValue.replaceAll(match[0], mentionUsername);

        const loadedMentionedUser = { mention, mentionUsername };

        this.loadedMentionedUsers = [
          ...this.loadedMentionedUsers.filter((x) => x.mention !== mention),
          loadedMentionedUser,
        ];
      }

      this.emitMentionedUsersIds(textValue);
    }
  }

  resetValue() {
    this.textValue = '';
  }

  emitMentionedUsersIds(text) {
    if (!text?.length) {
      this.mentionedUsersIdsChanged.emit([]);
      return;
    }

    let ids = [];

    const regex = /\[@[^\s]+:\d+\]/g;

    const matches = text.matchAll(regex);

    for (let match of matches) {
      const mention = match[0];

      const idStart = mention.indexOf(':') + 1;

      const id = mention.slice(idStart, mention?.length - 1);

      ids = [...ids, id];
    }

    const uniqueIds = [...new Set(ids)];

    this.mentionedUsersIdsChanged.emit(uniqueIds);
  }
}
