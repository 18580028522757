<ng-container
  *ngIf="{
    checkForUpdates: checkForUpdates$ | async,
    notificationsVisible: notificationsVisible$ | async,
    currentUser: currentUser$ | async,
    isAuth: isAuth$ | async
  } as data"
>
  <div class="layout" [ngClass]="{ 'login-page-background': !data.isAuth }">
    <ng-container *ngIf="data.isAuth">
      <div class="sidebar">
        <app-sidebar
          [userProfileImage]="userProfileImage$ | async"
          [numberOfUnreadNotifications]="unreadNotificationsCount$ | async"
          [numberOfActiveSurveys]="numberOfActiveSurveys$ | async"
          [notificationsActive]="data.notificationsVisible"
          [surveyActive]="surveySidebarActive$ | async"
          [isMobile]="isMobile$ | async"
          [numberOfActiveQuizzes]="numberOfActiveQuizzes$ | async"
          (logout)="logout()"
          (openNotifications)="toggleNotificationsDialog(true)"
          (showSurveyDialog)="toggleSurveyDialog(true)"
          (showQuizDialog)="toggleQuizDialog(true)"
        ></app-sidebar>
      </div>
    </ng-container>
    <div class="content-container" id="content-container">
      <router-outlet></router-outlet>
    </div>
  </div>

  <ng-container *ngIf="surveyDialogVisible$ | async as visible">
    <app-active-surveys-dialog
      [visible]="visible"
      [surveys]="surveys$ | async"
      [loaded]="surveysLoaded$ | async"
      (selected)="surveySelected($event)"
      (close)="toggleSurveyDialog(false)"
      (loadSurveys)="loadSurveys()"
    ></app-active-surveys-dialog>
  </ng-container>

  <ng-container *ngIf="quizDialogVisible$ | async as visible">
    <app-active-quiz-dialog
      [visible]="visible"
      [quizzes]="quizzes$ | async"
      [loaded]="quizLoaded$ | async"
      (selected)="quizSelected($event)"
      (close)="toggleQuizDialog(false)"
      (load)="loadActiveQuizzes()"
    ></app-active-quiz-dialog>

    <!-- <app-active-surveys-dialog
      [visible]="visible"
      [surveys]="surveys$ | async"
      [loaded]="surveysLoaded$ | async"
      (selected)="surveySelected($event)"
      (close)="toggleSurveyDialog(false)"
      (loadSurveys)="loadSurveys()"
    ></app-active-surveys-dialog> -->
  </ng-container>
</ng-container>
