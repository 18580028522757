import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textToUrl',
  pure: true,
})
export class TextToUrlPipe implements PipeTransform {
  transform(value: string): any {
    let newValue = value;

    const regex =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;

    const regexMatches = newValue.matchAll(regex);

    let matches = [];

    if (regexMatches) {
      for (const match of regexMatches) {
        if (matches.includes(match[0])) continue;

        matches = [...matches, match[0]];
      }

      matches.map((match) => {
        const matchHtml = `<a href=${match} target="_blank">${match}</a>`;

        newValue = newValue.replaceAll(match, matchHtml);
      });
    }

    return newValue;
  }
}
