import { User } from '../auth';
import { IDepartment } from './interfaces/department.interface';

export class Department {
  id: string;
  name: string;
  users?: User[];
  departments?: Department[];

  constructor(data: IDepartment) {
    this.id = data.id;
    this.name = data.attributes.name;
  }
}
