import { IQuizQuestion } from './interfaces/quiz-question.interface';

export class QuizQuestion {
  id: number;
  points: number;
  possibleAnswers: PossibleAnswer[];
  priority: number;
  question: string;
  timeLimit: number;
  type: string;

  constructor(data: IQuizQuestion) {
    this.id = Number(data.id);
    this.points = data.attributes.points;
    this.priority = data.attributes.priority;
    this.question = data.attributes.question;
    this.timeLimit = data.attributes.time_limit;
    this.type = data.attributes.type;
    this.possibleAnswers = data.attributes.possible_answers.map((answer) => {
      return {
        id: answer.id,
        answer: answer.answer,
      };
    });
  }
}

export interface PossibleAnswer {
  id: number;
  answer: string;
}
