<div class="container">
  <div class="form-container">
    <img src="assets/images/jenz-logo.png" class="login-jenz-logo" />
    <ng-container *ngIf="urlParams$ | async as urlParams">
      <app-set-password-form
        [headerlabel]="'login.resetPassword' | translate"
        [email]="urlParams?.userEmail"
        [code]="urlParams?.code"
        (setPassword)="passwordSet($event)"
      >
      </app-set-password-form>
    </ng-container>
  </div>
</div>

<p-toast></p-toast>
