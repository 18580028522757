<form [formGroup]="loginForm">
  <div class="login-form">
    <span class="p-input-icon-right p-float-label">
      <input type="text" pInputText formControlName="email" />
      <label>{{ "login.email" | translate }}</label>
    </span>
    <div class="password">
      <span class="p-input-icon-right p-float-label">
        <input [type]="passwordType" pInputText formControlName="password" />
        <i class="pi pi-eye-slash clickable" (click)="viewPassword()"></i>
        <label>{{ "login.password" | translate }}</label>
      </span>
      <span class="forgot-password clickable" (click)="forgotPassword()">{{
        "login.forgotPassword" | translate
      }}</span>
    </div>

    <button
      pButton
      [label]="'login.login' | translate"
      [ngClass]="{ disabled: !loginForm.valid }"
      [disabled]="!loginForm.valid"
      (click)="submit()"
    ></button>

    <p-button
      class="activate-account-button"
      [label]="'login.activateAccount' | translate"
      [text]="true"
      (click)="activateAccount()"
    ></p-button>

    <p-button
      class="activate-account-button"
      [label]="'login.signUp' | translate"
      [text]="true"
      routerLink="./registration"
    ></p-button>
  </div>
</form>
