<ng-container [ngSwitch]="selectedLanguage">
  <ng-container *ngSwitchCase="'hr'">
    <iframe
      #iframe
      src="https://jenz.app/privacy-policy-hr/?preview=true"
      style="border: none"
    ></iframe>
  </ng-container>
  <ng-container *ngSwitchCase="'sk'">
    <iframe
      src="https://jenz.app/privacy-policy-sk/?preview=true"
      style="border: none"
    ></iframe>
  </ng-container>
  <ng-container *ngSwitchCase="'bg'">
    <iframe
      src="https://jenz.app/privacy-policy-bg/?preview=true"
      style="border: none"
    ></iframe>
  </ng-container>
  <ng-container *ngSwitchCase="'sl'">
    <iframe
      src="https://jenz.app/privacy-policy-sl/?preview=true"
      style="border: none"
    ></iframe>
  </ng-container>
  <ng-container *ngSwitchCase="'de'">
    <iframe
      src="https://jenz.app/privacy-policy-de/?preview=true"
      style="border: none"
    ></iframe>
  </ng-container>
  <ng-container *ngSwitchCase="'it'">
    <iframe
      src="https://jenz.app/privacy-policy-it/?preview=true"
      style="border: none"
    ></iframe>
  </ng-container>
  <ng-container *ngSwitchCase="'mk'">
    <iframe
      src="https://jenz.app/privacy-policy-mk/?preview=true"
      style="border: none"
    ></iframe>
  </ng-container>
  <ng-container *ngSwitchCase="'pt'">
    <iframe
      src="https://jenz.app/privacy-policy-pt/?preview=true"
      style="border: none"
    ></iframe>
  </ng-container>
  <ng-container *ngSwitchCase="'al'">
    <iframe
      src="https://jenz.app/privacy-policy-al/?preview=true"
      style="border: none"
    ></iframe>
  </ng-container>
  <ng-container *ngSwitchCase="'en'">
    <iframe
      src="https://jenz.app/privacy-policy/?preview=true"
      style="border: none"
    ></iframe>
  </ng-container>
  <ng-container *ngSwitchDefault
    ><iframe
      #iframe
      src="https://jenz.app/privacy-policy/?preview=true"
      style="border: none"
    ></iframe
  ></ng-container>
</ng-container>
