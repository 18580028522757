import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from 'libs/services/auth';
import { LocalStorageClass } from 'libs/shared/functions/local-storage.class';
import { AuthState } from 'libs/states/auth';
import * as authActions from 'libs/states/auth/+state/auth.actions';
import * as authSelectors from 'libs/states/auth/+state/auth.selectors';
import * as notificationsActions from 'libs/states/notifications/+state/notifications.actions';
import { NotificationsState } from 'libs/states/notifications/+state/notifications.reducer';
import * as notificationsSelectors from 'libs/states/notifications/+state/notifications.selectors';
import { QuizState } from 'libs/states/quiz/+state/quiz.reducer';
import * as surveyActions from 'libs/states/survey/+state/survey.actions';
import { SurveyState } from 'libs/states/survey/+state/survey.reducer';
import * as surveySelectors from 'libs/states/survey/+state/survey.selectors';
import { UiState } from 'libs/states/ui-state/+state/ui.reducer';
import * as uiSelectors from 'libs/states/ui-state/+state/ui.selectors';
import { filter, interval, tap } from 'rxjs';

import * as quizActions from 'libs/states/quiz/+state/quiz.actions';
import * as quizSelectors from 'libs/states/quiz/+state/quiz.selectors';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent {
  searchText = '';
  isAuth = false;

  // Variable to detect if Jenz web tab is focused or not
  tabFocused = true;

  isAuth$ = this.authState.select(authSelectors.getIsAuth).pipe(
    tap((isAuth) => {
      this.isAuth = isAuth;

      if (isAuth) {
        this.notificationState.dispatch(
          notificationsActions.loadUnreadNotificationsCount()
        );
        this.surveyState.dispatch(surveyActions.loadActiveSurveysCount());

        this.quizState.dispatch(quizActions.loadActiveQuizzesCount());
      }
    })
  );

  notificationsLoaded$ = this.notificationState.select(
    notificationsSelectors.getNotificationsLoaded
  );

  notifications$ = this.notificationState.select(
    notificationsSelectors.getNotifications
  );

  notificationsVisible$ = this.notificationState.select(
    notificationsSelectors.getNotificationsVisible
  );

  userProfileImage$ = this.authState.select(
    authSelectors.getCurrentUserProfileImage
  );

  currentUser$ = this.authState
    .select(authSelectors.getCurrentUser)
    .pipe(tap((currentUser) => this.checkRefreshToken(currentUser?.email)));

  checkForUpdates$ = interval(60 * 1000).pipe(
    filter(() => this.isAuth && this.tabFocused),
    tap(() => {
      this.notificationState.dispatch(
        notificationsActions.loadUnreadNotificationsCount()
      );

      this.surveyState.dispatch(surveyActions.loadActiveSurveysCount());

      this.quizState.dispatch(quizActions.loadActiveQuizzesCount());
    })
  );

  unreadNotificationsCount$ = this.notificationState.select(
    notificationsSelectors.getUnreadNotificationsCount
  );

  surveyDialogVisible$ = this.surveyState.select(
    surveySelectors.getSurveyDialogVisible
  );

  surveys$ = this.surveyState.select(surveySelectors.getSurveys);

  surveysLoaded$ = this.surveyState.select(surveySelectors.getSurveysLoaded);

  numberOfActiveSurveys$ = this.surveyState.select(
    surveySelectors.getNumberOfActiveSurveys
  );

  surveySidebarActive$ = this.surveyState.select(
    surveySelectors.getSurveySidebarActive
  );

  numberOfActiveQuizzes$ = this.quizState.select(
    quizSelectors.getNumberOfActiveQuizzes
  );

  quizDialogVisible$ = this.quizState.select(
    quizSelectors.getQuizDialogVisible
  );

  quizzes$ = this.quizState.select(quizSelectors.getQuizzes);

  quizLoaded$ = this.quizState.select(quizSelectors.getQuizzesLoaded);

  isMobile$ = this.uiState.select(uiSelectors.getIsMobile);

  @HostListener('window:focus', ['$event'])
  onFocus(event: FocusEvent): void {
    // Tab is focused
    this.tabFocused = true;
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: FocusEvent): void {
    // Tab is not focused
    this.tabFocused = false;
  }

  constructor(
    private authState: Store<AuthState>,
    private notificationState: Store<NotificationsState>,
    private uiState: Store<UiState>,
    private surveyState: Store<SurveyState>,
    private router: Router,
    private authService: AuthService,
    private quizState: Store<QuizState>
  ) {}

  logout() {
    this.authState.dispatch(authActions.logout());
  }

  toggleNotificationsDialog(visible: boolean) {
    this.notificationState.dispatch(
      notificationsActions.toggleNotificationsVisible({ visible })
    );
  }

  loadAllNotifications() {
    this.notificationState.dispatch(
      notificationsActions.loadAllNotifications()
    );
  }

  loadSurveys() {
    this.surveyState.dispatch(surveyActions.loadActiveSurveys());
  }

  toggleSurveyDialog(visible: boolean) {
    this.surveyState.dispatch(surveyActions.toggleSurveyDialog({ visible }));
  }

  toggleQuizDialog(visible: boolean) {
    this.quizState.dispatch(quizActions.toggleQuizDialog({ visible }));
  }

  surveySelected(surveyId: string) {
    this.toggleSurveyDialog(false);

    this.router.navigate(['survey/', surveyId]);
  }

  // Check if token needs to be refreshed
  checkRefreshToken(email) {
    const token = LocalStorageClass.getToken();

    if (!token || !email?.length) return;

    const refreshToken = LocalStorageClass.getRefreshToken();

    if (!refreshToken) {
      this.getMissingRefreshToken();
      return;
    }

    const tokenCurrentDate = LocalStorageClass.getTokenSetDate();

    if (!tokenCurrentDate) {
      this.authState.dispatch(authActions.refreshCurrentToken());
      return;
    }

    const monthOldDate = new Date(
      new Date().getTime() - 30 * 24 * 60 * 60 * 1000
    );

    if (monthOldDate > new Date(tokenCurrentDate))
      this.authState.dispatch(authActions.refreshCurrentToken());
  }

  // If there are no refresh token, get current one and save it
  // This can be removed after 06/2024, everyone will have refresh token then
  getMissingRefreshToken() {
    this.authService.getMissingRefreshToken().subscribe();
  }

  quizSelected(quizId: number) {
    this.toggleQuizDialog(false);

    this.router.navigate(['quiz/', quizId]);
  }

  loadActiveQuizzes() {
    this.quizState.dispatch(quizActions.loadActiveQuizzes());
  }
}
