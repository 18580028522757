import { IQuiz } from './interfaces/quiz.interface';
import { QuizCategory } from './quiz-category.type';
import { QuizQuestion } from './quiz-question.type';

export class Quiz {
  id: number;
  title: string;
  category?: QuizCategory;
  endsAt: Date;
  introText?: string;
  published: boolean;
  startsAt: Date;
  groups?: any[];
  timeLimit?: number;
  metadata: any;

  questions: QuizQuestion[];

  constructor(data: IQuiz, questions?: QuizQuestion[]) {
    this.id = Number(data.id);
    this.title = data.attributes.title;
    this.category = data.attributes.category;
    this.endsAt = data.attributes.ends_at;
    this.groups = data.attributes.groups;
    this.introText = data.attributes.intro_text;
    this.published = data.attributes.published;
    this.startsAt = data.attributes.starts_at;
    this.timeLimit = data.attributes.time_limit;
    this.metadata = data.attributes?.metadata;

    if (questions) this.questions = questions;
  }
}
