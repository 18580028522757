import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { QuizEffects } from './+state/quiz.effects';
import {
  initialState,
  quizFeatureKey,
  quizReducer,
} from './+state/quiz.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(quizFeatureKey, quizReducer, {
      initialState,
    }),
    EffectsModule.forFeature([QuizEffects]),
  ],
  providers: [HttpClientModule, MessageService],
})
export class QuizStateModule {}
