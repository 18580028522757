import { IQuizResultAttributes } from './interfaces/quiz-result.interace';

export class QuizResult {
  points: number;
  quizTotalPoints: number;
  totalTimeSpent: number;

  constructor(attributes: IQuizResultAttributes) {
    this.points = Math.round(attributes.points * 100) / 100;
    this.quizTotalPoints = attributes.quiz_total_points;
    this.totalTimeSpent = attributes.total_time_spent;
  }
}
