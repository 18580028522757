<ng-container *ngIf="!isMobile; else mobileSidebar">
  <div class="sidebar-container">
    <img
      class="sidebar-logo clickable"
      src="assets/images/jenz-logo.png"
      routerLink="stories"
    />

    <div class="sidebar-items">
      <ng-container *ngFor="let item of sidebarItems">
        <div
          class="sidebar-item clickable"
          (click)="item?.clickFn ? item?.clickFn() : null"
          [routerLink]="item?.route"
          routerLinkActive="active-sidebar-item"
          gaEvent="sidebar-{{ item.name }}-click"
          [ngClass]="{
            'active-sidebar-item':
              item?.indicatorValue === 'survey' && surveyActive
          }"
        >
          <div class="image-container">
            <img [src]="'assets/icons/' + item.icon" class="sidebar-icon" />
            <ng-container *ngIf="item?.indicatorValue?.length">
              <ng-container [ngSwitch]="item?.indicatorValue">
                <ng-container *ngSwitchCase="'survey'">
                  <ng-container *ngIf="this.numberOfActiveSurveys > 0">
                    <div class="indicator-value">
                      {{ this.numberOfActiveSurveys }}
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'notification'">
                  <ng-container *ngIf="this.numberOfUnreadNotifications > 0">
                    <div class="indicator-value">
                      {{ this.numberOfUnreadNotifications }}
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'quiz'">
                  <ng-container *ngIf="this.numberOfActiveQuizzes > 0">
                    <div class="indicator-value">
                      {{ this.numberOfActiveQuizzes }}
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
          {{ "sidebar." + item.name | translate }}
        </div>
      </ng-container>
      <div
        class="sidebar-item clickable"
        gaEvent="sidebar-my-profile-click"
        routerLink="profile"
        routerLinkActive="profile-active-sidebar-item"
      >
        <ng-container *ngIf="userProfileImage; else showDefaultImage">
          <img
            class="sidebar-user-avatar clickable"
            [src]="userProfileImage"
            onError="this.src = 'assets/icons/user-avatar.svg'"
          />
        </ng-container>
        <ng-template #showDefaultImage>
          <img
            class="sidebar-user-avatar clickable"
            src="assets/icons/user-avatar.svg"
          />
        </ng-template>
        {{ "sidebar.myProfile" | translate }}
      </div>
    </div>

    <div
      class="clickable logout"
      (click)="logoutClicked()"
      gaEvent="sidebar-logout-click"
    >
      <i class="pi pi-sign-out" style="font-size: 20px; color: #f2f2f2"></i>
      {{ "sidebar.logout" | translate }}
    </div>
  </div>
</ng-container>

<ng-template #mobileSidebar>
  <div class="sidebar-container-mobile">
    <div class="sidebar-items-mobile">
      <ng-container *ngFor="let item of sidebarItems">
        <ng-container *ngIf="item?.showOnMobile">
          <img
            [src]="'assets/icons/' + item.icon"
            class="mobile-sidebar-icon"
            [routerLink]="item?.route"
            routerLinkActive="mobile-active-sidebar-item"
            gaEvent="mobile-navbar-{{ item?.name }}-click"
          />
        </ng-container>
      </ng-container>
      <ng-container *ngIf="userProfileImage; else showDefaultImage">
        <img
          loading="lazy"
          routerLink="profile"
          class="sidebar-user-avatar-mobile clickable"
          [src]="userProfileImage"
          onError="this.src = 'assets/icons/user-avatar.svg'"
          gaEvent="mobile-navbar-my-profile-click"
        />
      </ng-container>
      <ng-template #showDefaultImage>
        <img
          routerLink="profile"
          class="sidebar-user-avatar-mobile clickable"
          src="assets/icons/user-avatar.svg"
          gaEvent="mobile-navbar-my-profile-click"
        />
      </ng-template>
    </div>

    <div class="add-story-mobile" gaEvent="mobile-navbar-add-story-click">
      <i
        class="pi pi-plus"
        routerLink="story/new"
        routerLinkActive="active-sidebar-item"
      ></i>
    </div>
  </div>
</ng-template>
