<div class="forgot-password-container">
  <ng-container [ngSwitch]="activeStep">
    <ng-container *ngSwitchCase="forgotPasswordEnum.RequestCode"
      ><app-request-code-form
        [headerLabel]="'login.requestPasswordReset' | translate"
        [buttonLabel]="'login.sendRequest' | translate"
        (formSubmitted)="requestPasswordReset($event)"
      ></app-request-code-form>
    </ng-container>

    <ng-container *ngSwitchCase="forgotPasswordEnum.CodeReceivedConfirmation">
      <div>
        {{ "login.forgotPasswordMessage" | translate }}
      </div>
      <span
        class="clickable-link clickable"
        (click)="navigateToStep(forgotPasswordEnum.EnterCodeAndSetPassword)"
        >{{ "login.enterActivationCode" | translate }}</span
      >
      <div>{{ "login.codeNotReceived" | translate }}</div>
      <span
        class="clickable-link clickable"
        (click)="navigateToStep(forgotPasswordEnum.RequestCode)"
        >{{ "login.resendCode" | translate }}</span
      >
    </ng-container>

    <ng-container *ngSwitchCase="forgotPasswordEnum.EnterCodeAndSetPassword">
      <app-set-password-form
        [headerlabel]="'login.resetPassword' | translate"
        [email]="email"
        (setPassword)="setUserPassword($event)"
      ></app-set-password-form>
    </ng-container>
  </ng-container>

  <p-button
    class="login-button"
    [label]="'login.backToLogin' | translate"
    [text]="true"
    (click)="navigateBack()"
  ></p-button>
</div>
