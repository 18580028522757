import { ApiResponseSuccess } from '../core/core-responses.type';
import { IUser, IUserImage } from './interfaces/user.interface';

export class User {
  name: string;
  firstName: string;
  lastName: string;
  active: boolean;
  role: string;
  departmentName: string;
  jobPosition: string; // called title on backend
  title: string;
  email: string;
  id: any; // UPDATE
  profileImage: string;
  description: string;
  phoneNumber: string;
  slackUsername: string;
  msTeams: string;
  startedWorkingAt: string;
  tenant: string;
  communicationApp: string;
  defaultThemeColor: string;
  //   profileEditPermissions: UserPermissions;
  awayFrom: string;
  awayTo: string;
  awayType: string;
  clapAllowed: boolean;
  claps: number;
  new: boolean;
  lastActivity: LastActivity | null;
  deletedAt: DeletedAt | null;
  secondaryEmail: string;
  departmentId: number;
  number: string;
  hasCompanyProfile: boolean;
  companyProfileName: string;
  hideContactInfo: boolean;
  groups: {
    groupName: string;
    id: number;
    allowsSocialPublish: boolean;
  }[];

  // user form controls
  dialNumber: string;
  phone: string;
  slackId: string;
  skype: string;
  startedAt: string;
  tagName: string;

  constructor(data: IUser) {
    this.id = data.id;
    this.name = data.attributes.name;
    this.firstName = data.attributes.first_name;
    this.lastName = data.attributes.last_name;
    this.active = data.attributes.active;
    this.role = data.attributes.role;
    this.departmentName = data.attributes.department_name;
    this.jobPosition = data.attributes.title;
    this.title = data.attributes.title;
    this.email = data.attributes.email;
    this.secondaryEmail = data.attributes.secondary_email;
    this.profileImage = data.attributes.profile_image;
    this.description = data.attributes.description;
    this.phoneNumber = data.attributes.phone_number;
    this.communicationApp = data.attributes.communication_app;
    this.slackUsername = data.attributes.slack_username;
    this.msTeams = data.attributes.ms_teams_username;
    this.startedWorkingAt = data.attributes.started_working_at;
    this.tenant = data.attributes.tenant;
    this.communicationApp = data.attributes.communication_app;
    this.defaultThemeColor = data.attributes.default_theme_color;
    this.awayFrom = data.attributes.away_from;
    this.awayTo = data.attributes.away_to;
    this.awayType = data.attributes.away_type;
    this.clapAllowed = data.attributes.clap_allowed;
    this.claps = data.attributes.claps;
    this.new = data.attributes.new;
    this.lastActivity = new LastActivity(data.attributes);
    this.deletedAt = new DeletedAt(data.attributes);

    // create user controls
    this.phone = data.attributes.phone_number;
    this.slackId = data.attributes.slack_username;
    this.startedAt = data.attributes.started_working_at;
    this.skype = data.attributes.skype_username;
    this.departmentId = data.attributes.department_id;
    this.dialNumber = data.attributes.country_code;
    this.number = data.attributes.number;
    this.tagName = data.attributes.tag_name;
    this.hideContactInfo = data.attributes.hide_contact_info;
    this.groups = data.attributes.groups.map((group) => {
      return {
        groupName: group.name,
        id: group.id,
        allowsSocialPublish: group.allows_social_publish,
      };
    });

    this.companyProfileName = data.attributes.company_profile_name;
    this.hasCompanyProfile = data.attributes.has_company_profile;
  }
}

export class UserImage {
  id: any; // UPDATE
  mime: string;

  constructor(data: IUserImage, wholeResponse: ApiResponseSuccess<IUserImage>) {
    this.id = data.id;
    this.mime = data.attributes.mime;
  }
}

export class UserPermissions {
  profileImage: boolean;
  phoneNumber: boolean;
  description: boolean;

  constructor(data: any) {
    this.profileImage = data.profile_image;
    this.phoneNumber = data.phone_number;
    this.description = data.description;
  }
}

export class DeletedAt {
  date: string;
  timezone: string;
  timezoneType: number;

  constructor(data: any) {
    this.date = data.deleted_at?.date || null;
    this.timezone = data.deleted_at?.timezone || null;
    this.timezoneType = data.deleted_at?.timezone_type || null;
  }
}

export class LastActivity {
  date: string;
  timezone: string;
  timezoneType: number;

  constructor(data: any) {
    this.date = data.last_activity?.date || null;
    this.timezone = data.last_activity?.timezone || null;
    this.timezoneType = data.last_activity?.timezone_type || null;
  }
}

export interface UserGroup {
  id: number;
  name: string;
  allows_social_publish: boolean;
}
