import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'libs/shared/shared.module';
import { AuthStateModule } from 'libs/states/auth';
import { NotificationsStateModule } from 'libs/states/notifications/notifications-state.module';
import { QuizStateModule } from 'libs/states/quiz/quiz-state.module';
import { SurveyStateModule } from 'libs/states/survey/survey-state.module';
import { UiStateModule } from 'libs/states/ui-state/ui-state.module';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ActiveQuizDialogComponent } from './components/active-quiz-dialog/active-quiz-dialog.component';
import { SelectLanguageComponent } from './components/select-language/select-language.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ActiveSurveysDialogComponent } from './components/survey-dialog/active-surveys-dialog.component';
import { TermsAndConditionsDialogComponent } from './components/terms-and-conditions-dialog/terms-and-conditions-dialog.component';
import { LayoutComponent } from './containers/layout/layout.component';
import { NotificationIconPipe } from './pipes/notification-icon.pipe';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: LayoutComponent,
  },
];

@NgModule({
  declarations: [
    LayoutComponent,
    SidebarComponent,
    NotificationIconPipe,
    ActiveSurveysDialogComponent,
    TermsAndConditionsDialogComponent,
    SelectLanguageComponent,
    ActiveQuizDialogComponent,
  ],
  imports: [
    CommonModule,
    InputTextModule,
    FormsModule,
    AuthStateModule,
    UiStateModule,
    NotificationsStateModule,
    DialogModule,
    ScrollPanelModule,
    ProgressSpinnerModule,
    SurveyStateModule,
    TranslateModule,
    SharedModule,
    NgxGoogleAnalyticsModule,
    RadioButtonModule,
    QuizStateModule,
    RouterModule.forChild(routes),
  ],
  exports: [
    LayoutComponent,
    TermsAndConditionsDialogComponent,
    SelectLanguageComponent,
  ],
})
export class LayoutModule {}
